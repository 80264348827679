<script>
    import PurchasePaddle from './PurchasePaddle.svelte';
    import Button from './Button.svelte';
    import PurchasePaddleGift from './PurchasePaddleGift.svelte';
    import Video from './Video.svelte';
    import Prose from './Prose.svelte';
    import { logEvent } from './utils/logs';

    let className = '';
    export { className as class };

    /** @type {Course}*/
    export let course;
    export let header_tag = 'h1';
    export let title = 'Heroic title';
    export let text = 'Heroic text';

    /** @type {string}*/
    export let playback_id;
    export let thumbnail_time = 0;
    export let autoplay = false;
    export let feature = 'hero';

    const handle_play = () => {
        logEvent('video-play', {
            course: course.slug
        });
    }

    const handle_ended = () => {
        logEvent('video-ended', {
            course: course.slug
        });
    }

</script>

<section class="lg:flex lg:items-start lg:justify-center {className}">
    {#if playback_id}
        <div
            class="mb-12 w-full lg:w-6/12 xl:w-7/12 2xl:w-9/12 lg:ml-8 aspect-video rounded-2xl overflow-hidden bg-black order-1"
        >
            <Video {playback_id} {autoplay} {thumbnail_time} on:play={handle_play} on:ended={handle_ended}/>
        </div>
    {/if}

    <Prose lg class="w-full lg:w-6/12 xl:w-5/12 2xl:w-4/12">
        <svelte:element this={header_tag}>
            {#if header_tag !== 'h1'}
                <a class="link" href="/courses/{course.slug}">{@html title}</a>
            {:else}
                {@html title}
            {/if}
        </svelte:element>

        {@html text}

        <div
            class="not-prose flex flex-col items-center md:flex-row md:items-center lg:flex-col lg:items-start gap-4"
        >
            {#if !course.purchased && course?.price}
                <div class="flex flex-row flex-nowrap gap-4">
                    <PurchasePaddle
                        product={course}
                        cta="Buy Now"
                        {feature}
                    />
                    <PurchasePaddleGift {course} {feature} />
                </div>
                <span
                    >Or <a
                        class="link"
                        href="/courses/{course.slug}/lessons"
                        >Try First Lesson for Free</a
                    ></span
                >
            {:else if course.purchased && course?.lessons.length && course?.complete}
                <div>
                    {#if !course?.review}
                        <Button primary route="/courses/{course.slug}/review"
                            >Write a Review</Button
                        >
                    {/if}
                    <Button outline route="/courses/{course.slug}/lessons"
                        >View Results</Button
                    >
                </div>
            {:else if course.purchased}
                <Button primary route="/courses/{course.slug}/lessons"
                    >Continue Your Journey</Button
                >
            {/if}
        </div>
    </Prose>
</section>
