<script>
    import Reviews2Item from "./Reviews2Item.svelte";

    export let reviews = [];
    // console.log('reviews', reviews);

    let className = '';
    export { className as class };
    export let review_class = '';
</script>

<div class="{className}">
    {#each reviews as review}
        <Reviews2Item
            review={review}
            class={review_class}
        />
    {/each}
</div>