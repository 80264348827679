<script>
    import { Marked } from "marked";
    import Button from "./Button.svelte";
    

    let marked = new Marked({breaks: true});


    let className = '';
    export { className as class };
    export let review = {};

    let collapse = review.text.length > 240;

    let text = collapse ? review.text.slice(0, review.text.slice(0, 160).lastIndexOf(' ')) + '...' : review.text;
    let collapsed = true;


    const handle_expand = () => {
        collapsed = false;
        text = review.text;
    };

</script>

<blockquote
    class="flex flex-col gap-1 {className}"
>
    {#if review.rating}
        <div class="text-yellow-500">
            {#each Array(5) as _, i}
                {i < review.rating ? '★' : '☆'}
            {/each}
        </div>
    {/if}
    <div>
        {@html marked.parse(text)}

        {#if collapse && collapsed}
            <Button on:click={handle_expand} link nop>Read more</Button>
        {/if}
    </div>
    <cite class="text-base">&mdash; {review.author}</cite>
</blockquote>


<style lang="postcss">
    blockquote :global(p) {
        margin-bottom: theme('spacing.2');
    }
</style>